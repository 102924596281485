@import "../../global.scss";
.modules{
    flex-direction: column;
    width: 100%;
    display: flex;
    align-items: center;

    &.mobile{
        min-height: 50vh;


        .container{
            .item{
                width: 350px;
                height: 230px;
                margin: 20px;
                font-size: $mobileContentSize;
            }
        }

        .pagination{
    
            ul{
                & .MuiPaginationItem-root {
                    font-size: 40px;
                    overflow: visible;
                }
            }
        }
    }

    .container{
        display: grid;
        justify-items: center;
    
        .item{
           width: 220px;
           height: 150px;
           border-radius:  20px;
           border: 1px solid $outline; 
           margin: 10px;
           display: flex;
           grid-row: span 1;
           grid-column: span 1; 
           
           align-items: center;
           justify-content: center;
           color: $mainColor;
           position: relative;
           transition: all .5s ease;
           cursor: pointer;
           overflow: hidden;
           background-color: $moduleHighlight;
           font-size: $contentLargeSize;
    
           h3{
               position: absolute;
               font-size: inherit;
               text-align: center;
               padding: 2px;
           }
    
           h4{
               font-size: inherit;
               position: absolute;
               z-index: 10;
               color: $mainColor;
               bottom: 0;
               background-color: $outline;
               width: 100%;
               text-align: center;
               opacity: 1;
           }
    
           img{
               transition: all .7s ease;
               width: 100%;
               height: 100%;
               object-fit: cover;
               overflow: hidden;
               opacity: 0.2;
               z-index: 0;
               filter: grayscale(100);
           }

           &:hover{
    
               img{
                   opacity: 1;
                   z-index: 10;
                   filter: grayscale(0);
               }

               h3{
                    // @include mobile{
                    //     font-size: 14px;
                    // }
               }
           }
        }
    }

    .pagination{
        margin: 0;
        display:flex;
        justify-content: center;
        width: 100%;

        // .MuiPagination-root {
        // }

        ul{
            margin: 0;
            width: 100%;
            & .MuiPaginationItem-root {
                color: white;
            }
        }
    }
}
