@import "../../global.scss";

.projects {

    .header{
        display: flex;
        flex-direction: column;
        align-items: center;

        h1{
            z-index: 30;
        }
    }

    .controls{
        display: flex;
        z-index: 40;
        flex-direction: column;
        align-items: center;
        margin: 20px 0px;
        justify-content: center;
        align-items: center;
        font-size: $contentSize;
    }

    .swiper-pagination-bullet {
        background-color: white;
    }
    

    .swiper-slide {
        background-position: center;
        background-size: cover;
        min-height: 400px;
        min-width: 350px;
        width: 33vh;
        height: calc(100vh - 400px);
        max-width: 450px;
        max-height: 600px;
        overflow-y: hidden;
        background: #333;
        border-radius: 30px;
        box-shadow: 0 3px 10px white;
    }

    .card {
        position: relative;
        width: auto;
        height: 100%;
        margin: 0 auto;
        background: #333 ;
        padding: 20px 20px;
        text-align: center;
        overflow: hidden;

        .layer{
            position: absolute;
            top: calc(100% - 5px);
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient($primarySelect, $secondarySelect);
            z-index: 1;
            transition: 0.5s;
        }

        .content{
            position: relative;
            z-index: 2;
            
            h2{
                justify-content: center;
                display: flex;
                font-weight: 300;
            }
            
            .tags{
                margin: 10px 0;

                .tag{
                    text-align: left;
                    display:inline-block;
                    font-size: $contentSize;
                    padding: 5px;
                    color: $mainColor;
                    margin: 3px;
                    cursor: pointer;
                    border-radius: 10px;
                    background-color: $secondarySelect;
                }
            }
            

            p{
                font-size: $contentSize;
                line-height: $contentXLargeSize;
                font-family: inherit;
                margin-bottom: 10px;
            }

            .projectCover{
                border-radius: 30px;
                height: 30%;
                margin: 0 auto;
                overflow: hidden;
                object-fit:contain;

                img{
                    width: 85%;
                    height: auto;
                    transition: 0.5s;
                }
            }

            .timeframe{
                font-weight: 300;
                color: $tertiaryColor;
                font-size: $contentSize;
            }

        }

        // .btn{
        //     position: absolute;
        //     bottom: -20px;
        // }
    }

        
    .card:hover {

        .layer{
            top:0;
        }

        .content{
            .projectCover{
                img{
                    filter: grayscale(100%);
                }
            }
        }
    }

    .swiper-container {
        z-index: 3;
        margin: 0 25px;
        -webkit-box-reflect: below 1px linear-gradient(transparent 70%, #0009);
        overflow-y: hidden;
    }
    
    .swiper-wrapper {
        overflow: visible;
        list-style: none;
    }

    .no-projects{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 100px;

        h2{
            font-size: $ProjectCardHeader;
            font-weight: 300;
            display: flex;
        }
    }

    &.mobile{

        .controls {
            margin-bottom: 10px;
            font-size: $mobileContentSize;
        }

        .mobile-project-container{
            position: relative;

            h2{
                font-size: $mobileContentLargeSize;
                text-align: center;
                padding: 20px;
            }
        }

        .card:hover {
            .content{
                .projectCover{
                    img{
                        filter: none;
                    }
                }
            }
        }

        .card {
            padding: 50px;
            background: none;
            margin: 0 10%;

            .swipe-instructions{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-bottom: 20px;

                h3{
                    font-size: $mobileContentLargeSize;
                    font-weight: 300;
                    color: $tertiaryColor;
                }
            }

            .tags{

                .tag{
                    font-size: $mobileContentSize;
                    border-radius: 15px;
                }
            }
            
            .content{
                h2{
                    font-size: $mobileContentXLargeSize;
                }
                
                p{
                    font-size: $mobileContentSize;
                    line-height: $mobileContentLargeSize;
                    overflow-y: hidden;
                    font-family: inherit;
                    margin-bottom: 30px;
                }

                .timeframe{
                    font-size: $mobileContentSize;
                }
                
            }

        }
    }
}