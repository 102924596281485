$mainColor: white;
$secondColor: rgb(230, 230, 230);
$tertiaryColor: rgb(200, 200, 200);
$highlight: beige;
$background: rgb(23, 17, 25);
$outline: rgb(26, 20, 27);
$menuColor: rgba(23, 17, 25, 0.95);
$moduleHighlight: rgb(150, 0, 55);
$primarySelect: rgb(74, 89, 199);
$secondarySelect: rgb(235, 0, 85);

$width:768px;
$contentSize: 16px;
$contentLargeSize: 18px;
$contentSmallSize: 11px;
$contentXLargeSize: 25px;

//Internship
$internshipCardHeader: 60px;
$mobileInternshipCardHeader: 90px;

//Project
$ProjectCardHeader: 50px;

//mobile
$mobileContentSize: 30px;
$mobileContentLargeSize: 40px;
$mobileContentSmallSize: 25px;
$mobileContentXLargeSize: 60px;

@mixin mobile {
    @media (max-width: #{$width}){
        @content
    }
}

h1{
    font-size: 35px;

    &.mobile{
        margin-top: 20px;
        margin-bottom: 40px;
        font-size: 75px;
    }
}

div .btn{
    font-size: 14px;

    &.mobile{
        border-radius: 20px;
        font-size: 35px;
    }
}

a {
    text-decoration: none;
}