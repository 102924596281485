@import "../../global.scss";

.educationList{
    font-size: inherit;
    // margin-right: 50px;
    padding: 7px;
    border-radius: 10px;
    cursor: pointer;
    color: $mainColor;
    z-index: 10;
    display: inline-block;
    
    &.active{
        background-color: $secondarySelect;
        color: $mainColor;
    }
}