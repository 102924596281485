@import "../../global.scss";

.intro{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include mobile{
        flex-direction: column;
        align-items: center;
    }

    
    .imageContainer{       

        .me{
            z-index: 5;
            overflow: hidden;
            position: absolute;
            left: 15vw;
            top: 50px;
            z-index: 5;
            
            img{
                z-index: 5;
                height: 60vh;
                min-height: 400px;
                
                
                @include mobile{
                    max-height: 70vh;                    
                    filter: brightness(50%);
                }
            }
        }
    }

    .introParagraph{
        z-index: 10;
        margin: 20vh 5vw;
        width: 40vw;
        
        @include mobile{
            margin: 50px 40px;
            width: 80vw;
        }

        h1{
            margin-bottom: 20px;
        }

        p{
            font-weight: 300;
            color: $tertiaryColor;
            font-size: $contentLargeSize;
        }

        a{
            text-decoration: none;
        }

        .buttonContainer{
            display: flex;
            align-items: center;
            flex-direction: row;
            margin-top: 30px;
            
            .btn{
                margin-right: 30px;
            }
        }
    }

    &.mobile{
        flex-wrap: wrap;
        
        .imageContainer{
            display: flex;
            .me{
                overflow: hidden;
                position: absolute;
                left: 15vw;
                top: 50px;
                z-index: 5;
                
                img{
                    z-index: inherit;
                    max-height: 70vh;                    
                    filter: brightness(50%);
                }
            }
        }

        .introParagraph{
            z-index: 10;
            
            margin-left: auto;
            margin-right: auto;
            margin-top: auto;
            margin-bottom: calc(15vh + 10px);
            max-height: 80vh;
            width: 80vw;
            text-align: left;

            p{
                font-weight: 300;
                color: $tertiaryColor;
                font-size: $mobileContentLargeSize;
            }

            a{
                text-decoration: none;
            }

            .buttonContainer{
                display: flex;
                align-items: center;
                flex-direction: row;
                margin-top: 30px;

                .btn{
                    margin-right: 50px;
                }
                
                .portfolio{
                    margin-left: 30px;
                }
            }
        }
    }
}