@import "../../global.scss";

.contact {
    width: 100%;

    .header{
        margin-top: 35px;
        display: flex;
        flex-direction: column;
        align-items: center;
    
    }

    .messageBody{
        display: block;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;

        width: 80%;
        max-width: 900px;

        .invalid-email{
            display: flex;
            flex-direction: row;
            margin-left: 19px;
            
            .btn{
                color: $tertiaryColor;
            }

            h4{
                display: inline-flex;
                margin-left: 20px;
                padding-top: 5px;
                color: $secondarySelect;
            }
        }

        .formWord{
            padding: 0 20px;
            margin-top: 20px;
            font-size: $contentSize;
            span{
                line-height: 40px;
                font-size:inherit;
            }

            input{
                border: none;
                font-size:inherit;
                color: $mainColor;
                background-color: #333;
                width: 100%;
                padding: 15px;
                // transition: 1s ease;

                &:focus{
                    outline: none;
                    text-decoration: none;
                    border-bottom: solid 1px $secondarySelect;
                    color: $secondarySelect;
                }
            }

            textarea{
                transition: 1s ease;
                font-size:inherit;
                margin-bottom: 10px;
                outline: none;
                box-shadow: none;
                border: none;
                width: 100%;
                padding: 15px;
                color: $mainColor;
                background-color: #333;
                min-height: 100px;
                &:focus{
                    // border: none;
                    text-decoration: none;
                    border-bottom: solid 1px $secondarySelect;
                    outline: none;
                    box-shadow: none;
                    color: $secondarySelect;
                }
            }
        }

        .row{
            margin-top: 20px;
        }
    }

    &.mobile{
        .messageBody{
            .invalid-email{
                h4{
                    font-size: $mobileContentSize;
                }
            }

            .formWord{
                margin-bottom: 10px;
                font-size: $mobileContentLargeSize;

                input{
                    margin: 30px 0;
                }

                textarea{
                    height: calc(100vh - 900px);
                    min-height: 50px;
                    max-height: 500px;
                    margin: 30px 0;
                }
            }
        }
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    font-size: 60px;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}