@import '../../global.scss';

.education{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    z-index: 10;

    .header{
        z-index: inherit;
        align-self: center;
        text-align: center;
        overflow: hidden;
        overflow: hidden;

        h1{
            margin-bottom: 5px;
            overflow: hidden;
        }

        .description{
            overflow: hidden;
            color: $tertiaryColor;
            font-family: 'Roboto Mono', monospace;
            font-weight: 300;
            font-size: $contentSize;
            padding-bottom: 10px;
            span{
                font-family: inherit;
            }

            a{
                font-family: inherit;
                color: inherit;
                text-decoration: inherit;
            }

            h2{
                color: $secondColor;
                font-family: inherit;
                display: inline;
                font-size: inherit;
                font-weight: 500;
                font-style: bold;
            }
        }
    }

    ul{
        margin: 10px;
        padding:0;
        list-style: none;
        display: flex;
        width: 75%;
        flex-wrap: wrap;
        margin-left: 10%;
        overflow: hidden;
    }

    .swiper-wrapper {
        overflow: visible;
        list-style: none;
        // margin-left: 40px;
    }

    .swiper-container{
        max-width: 70vw;
    }

    .swiper-slide {
        font-size: $contentSize;
        width: 120px;
    }

    .swiper-button-prev{
        color: rgba(0, 0, 0, 0);
        background-image: url("./prev.png");
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center;
        margin-left: -12px;
    }

    .swiper-button-next{
        color: rgba(0, 0, 0, 0);
        background-image: url("./next.png");
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center;
        margin-right: -12px;
    }

    .filler{
        width: 20px;
    }

    &.mobile{
        .header{
            .description{
                font-size: $mobileContentSize;

            }
        }

        .swiper-slide{
            font-size: $mobileContentSize;
            width: 200px;
        }

        .swiper-container{
            margin: 20px 0;
        }

        .swiper-button-prev{
            display: none;
        }

        .swiper-button-next{
            display: none;
        }

    }
}