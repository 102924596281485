@import "../../global.scss";

.topbar {
    width: 100%;
    height: 70px;
    background-color: $background;
    color: $mainColor;
    position: fixed;
    z-index: 1000;
    transition: all 1s ease;

    .wrapper{
        overflow: hidden;
        padding: 10px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left{
            display: flex;
            align-items: center;

            .logo{
                color: inherit;
                margin-right: 40px;

                .logoIcon{
                    width: 46px;
                    height: 46px;
                }

                &:hover{
                    color: $secondarySelect;
                }
            }

            .itemContainer{
                display: flex;
                align-items: center;
                margin-left: 30px;
                font-size: $contentSize;

                @include mobile{
                    display: none;
                }

                .icon{
                    font-size: $contentLargeSize;
                    margin-right: 5px;
                }

                span{
                    font-size: inherit;
                    font-weight: 500;

                    a{
                        font-weight: inherit;
                        font-size: inherit;
                        color: inherit;
                        text-decoration: inherit;
                    }
                }

                &:hover{
                    color: $secondarySelect;
                }
            }
        }

        .right {

            .hamburger{
                width: 32px;
                height: 22px;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                cursor: pointer;

                span{
                    width: 100%;
                    height: 2px;
                    background-color: $mainColor;
                    transform-origin: left;
                    transition: all 2s ease;
                }
            }
        }
    }

    &.mobile{
        overflow: visible;
        background-color: rgba(0, 0, 0, 0);
        .wrapper{
            .left{
                .itemContainer{
                    display: none;
                }

                .logo{
                    .logoIcon{
                        margin-top: 10px;
                        width: 80px;
                        height: auto;
                    }
                }
            }

            .right {

                .hamburger{
                    width: 74px;
                    height: 54px;
                    cursor: pointer;
    
                    span{
                        width: 100%;
                        height: 5px;
                        transform-origin: left;
                        transition: all 2s ease;
                    }
                }
            }
        }
    }

    &.active{
        color: $primarySelect;

        .hamburger{
            span{
                &:first-child{
                    background-color: $secondarySelect;
                    transform: rotate(45deg);
                }
                &:nth-child(2) {
                    opacity: 0;
                }
                &:last-child{
                    background-color: $primarySelect;
                    transform: rotate(-45deg);
                }
            }
        }
    }
}