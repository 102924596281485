@import "../../global.scss";

.menu{
    width: 300px;
    height: 100vh;
    background-color: $menuColor;
    position: fixed;
    top: 0;
    right: -300px;
    z-index: 900;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    font-size: $contentXLargeSize;
    
    &.mobile{
        width: 90vw;
        right: -90vw;
        font-size: $mobileContentXLargeSize;
        line-height: calc(6vh + 20px);
    }

    &.active{
        right: 0;
    }

    ul{
        font-size: inherit;
        margin: 0;
        margin-left: 40%;
        padding: 0;
        list-style: none;
        font-weight: 300;
        width: 100%;

        li{
            width: 100%;
            margin-bottom: 25px;
            a{
                display: block;
                width: 100%;
                color: $mainColor;
                font-size: inherit;
                text-decoration: none;
                
                &:hover {
                    font-weight: 500;
                    color: $secondarySelect;
                }
            }
        }
    }
}