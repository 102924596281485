@import "../../global.scss";
.skills{
    scrollbar-width: none; //for firefox
    &::-webkit-scrollbar{
        display: none;
    }

    .header{
        display: flex;
        flex-direction: column;
        align-items: center;
    
        .subtitle{
            font-weight: 300;
            font-size: $contentSize;
    
            .btn{
                margin: 10px 20px;
            }
        }
    }
    
    .skill_stat{
        margin: 10px 20px;
        display: grid;
        grid-template-columns: repeat( auto-fit, minmax(290px, 1fr));

        justify-items: center;

        .panel_collection{
            background-color: $primarySelect;
            width: 270px;
            padding: 7px;
            border-radius: 15px;
            overflow: hidden;
            margin: 10px;
            
            .collection_header{
                display: flex;
                justify-content: space-between;
                h2{
                    font-size: $contentLargeSize;
                    font-weight: 300;
                    display: flex;
                }
                .expand_btn{
                    cursor: pointer;
                }
            }
        
            .panels{
                transition: 1s ease;
                width: 100%;
                max-height: 0;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;

                .panel{
                    background-color: #333;
                    flex-direction: row;
                    justify-content: center;
                    height: 30px;
                    width: 85%;
                    padding: 20px;
                    border-radius: 15px;
                    box-shadow: 4px solid "white";
                    margin: 5px;
                    overflow: hidden;
                
                    .panel_text{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        margin-bottom: 10px;
                
                        h4{
                            font-weight: 300;
                            visibility: hidden;
                        }
                
                        h3{
                            font-weight: 500;
                            font-size: $contentSize;
                        }
                    }
                    
                }
        
                &.active{
                    max-height: 1000px;
        
                    .panel{
                        h4{
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }

    &.mobile{
        .header{

            .subtitle{
                font-size: $mobileContentLargeSize;

                .btn{
                    font-size: inherit;
                }
            }
        }

        .skill_stat{
            margin: 10px 20px;
            display: grid;
            grid-template-columns: 1fr;

            
            .panel_collection{
                width: 80vw;

                .collection_header{
                    font-size: $mobileContentLargeSize;

                    h2{
                        font-size: $mobileContentLargeSize;
                    }
                }

                .panels{
                
                    .panel{
                        height: 60px;

                        .panel_text{
                            h3{
                                font-size: $mobileContentSize;
                            }
                            h4{
                                font-size: $mobileContentSize;
                            }    
                        }
                    }
                }
            }
       }
    }
}
