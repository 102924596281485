@import "../../global.scss";

.internships{
    display: flex;
    flex-direction: column;
    align-items: center;

    .header{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .container{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin: 40px 0;
        max-width: 1200px;
        overflow: hidden;
        z-index: 30;


        .card{
            position: relative;
            overflow: hidden;
            width: 45vh;
            height: 45vh;
            max-height: 400px;
            max-width: 400px;
            min-height: 300px;
            min-width: 300px;
            margin: 30px;
            border-radius: 15px;
            box-shadow: inset 5px 5px 5px rgba(0,0,0,0.2),
                        inset  -5px -5px 5px rgba(255,255,255,0.1),
                                5px 5px 5px rgba(0,0,0,0.03),
                                -5px -5px 5px rgba(255,255,255,0.1);
            overflow: visible;
            z-index: 30;

            .box{
                overflow: hidden;
                z-index: 40;
                position: absolute;
                top: 20px;
                left: 20px;
                right: 20px;
                bottom: 20px;
                backdrop-filter: blur(10px);
                border: 2px solid rgba(74, 89, 199, 0.5);
                border-radius: 15px;
                background: rgba(255, 255, 255, 0.05);
                box-shadow: 0 20px 50px rgba(0,0,0,0.5);
                transition: 1s;
                padding: 30px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                *{
                    overflow: hidden;
                }

                .content{
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    opacity: 0.6;
                    transition: 1s;
                    
                    h2{
                        position:relative;
                        font-size: $internshipCardHeader;
                        font-weight: 300;
                        margin-top: 30px;
                    }
    
                    .contentBx{
                        h3{
                            color: white;
                            text-transform: uppercase;
                            letter-spacing: 2px;
                            font-weight: 500;
                            font-size: $contentLargeSize;
                            text-align: center;
                            margin: 20px 0 10px;
                            line-height: 1.1em;
    
                            span{
                                font-size: $contentSmallSize;
                                font-weight: 300;
                                text-transform: initial;
                            }
                        }
                    }

                    .btn{
                        margin-top: 10px;
                    }

                    p{
                        color: $tertiaryColor;
                        font-size: $contentSize;
                        font-weight: 300;
                    }
                }

                
                &:hover{
                    transform: translateY(-50px);
                    box-shadow: 0 40px 70px rgba(0,0,0,0.5);
                    border: 2px solid $secondarySelect;

                    .content{
                        opacity: 1;
                        
                        .contentBx{
                            opacity: 1;
                        }

                        .btn{
                            background-color: $secondarySelect;
                        }
                    }
                }
            }
        }
    }

    &.mobile{
        .container{
            .card{
                min-width: 400px;
                min-height: 400px;
                width: 36vh;
                height: 36vh;
                max-width: none;
                max-height: none;
                
                .box{
                    
                    .content{

                        h2{
                            font-size: $mobileInternshipCardHeader;
                        }

                        .contentBx{
                            h3{
                                font-size: $mobileContentLargeSize;

                                span{
                                    font-size: $mobileContentSize;
                                }
                            }
                        }

                        p{
                            font-size: $mobileContentSize;
                            margin-bottom: 30px;
                        }
                    }
                }
            }
        }
    }
}