@import '../../global.scss';

.article{
    z-index: 1010;
    background: $background;

    position: fixed;
    top: 0;
    bottom: 0;
    justify-content: center;
    min-height: 100vh;

    width: 100vw;

    .article-topbar{
        overflow: hidden;
        z-index: 2100;
        width: 95%;
        position: fixed;
        top: 0;
        height: 100px;
        overflow-x: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background: $background;
        align-items: center;

        .article-search{
            width: calc(90% - 120px);
            max-width: 600px;
        }

        .back{
            color: $mainColor;
            width: 50px;
            font-size: 40px;
            margin-top: 5px;

            &:hover{
                cursor: pointer;
                color: $secondarySelect;
            }
        }

        .close{
            display: flex;
            border-radius: 50%;
            font-size: 50px;
            // position: fixed;
            // top: 30px;
            // right: 30px;
            color: $mainColor;

            &:hover{
                cursor: pointer;
                color: $secondarySelect;
            }
        }
    }

    &.mobile{
        .article-topbar{
            height: 130px;

            .back{
                margin-top: 15px;
                font-size: 90px;
            }
            
            .article-search{
                font-size: $mobileContentSize;
            }

            
            .close{
                font-size: 100px;
            }
        }
        .container{
            .content{
                margin-top: 70px;
    
                max-width: 95%;
                font-size: $mobileContentSize;
    
                .body{
                    
                    .paragraph{
                        h2{
                            font-size: $mobileContentLargeSize;
                        }
                    }
                    
                    .video{
                        span{
                            font-size: $mobileContentSize;
                        }
                        
                        .video-responsive{
                            iframe {
                                // width: calc(100% - 20px);
                            }
                         
                        }
                    }
                }
            }
        }
    }

    
    .container{
        margin-top: 100px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .content{
            margin-top: 50px;
            // overflow-x: visible;
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 850px;
            color: $mainColor;
            font-size: $contentSize;
            padding-bottom: 10vh;

            
            .header{
                width: 100%;
                h1{
                    text-align: center;
                    width: 100%;
                }
                background: $background;
            }
        
            span{
                color: $tertiaryColor;
                font-weight: 300;
                font-size: inherit;
                width: 100%;
                text-align: center;
            }

            .body{
                font-size: inherit;
                color: $mainColor;
                width: 100%;
                max-width: 95vw;

                .paragraph{
                    margin-left: 5%;
                    width: 90%;
                    font-size: inherit;
                    margin-top: 20px;

                    h2{
                        font-size: $contentXLargeSize;
                    }
        
                    p{  
                        font-size: inherit;
                        line-height: inherit;
                        color: white;
                        span{
                            color: inherit;
                            font-size: inherit;
                        }
                    }
                }
        
                .media-img {
                    display: flex;
                    font-size: inherit;
                    justify-content: center;
                    flex-direction: column;
                    
                    span{
                        font-size: inherit;
                        text-align: center;
                        color: $secondColor;
                    }

                    .img-container{
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        margin-bottom: 15px;
                        
                        img{
                            object-fit: cover;
                        }

                        
                    }
                }

                .video{
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    align-items: center;

                    span{
                        font-size: $contentSize;
                        margin-top: 15px;
                    }

                    .video-responsive{
                        overflow: hidden;
                        display: block;
                        position: relative;
                        height: 0;
                        width: 90%;
                        padding: 0% 0% 56.25%;
                        margin: auto;
                     
                        iframe {
                            position: absolute;
                            top: 0; bottom: 0; left: 0;
                            width: 100%;
                            height: 100%;
                            border: 0;
                        }
                    }
                }

                .link{
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    margin: 20px 0;
                }
            }

            
        }
    }
}