@import "./global.scss";

.app{
    height: 100vh;
    background-image: url("./backgroundMain.png");
    background-color: $background;
    background-position: center;
    background-repeat: no-repeat;
    background-size:300%;

    .sections{
        width: 100%;
        height: calc(100vh - 70px);
        color: $mainColor;
        position: relative;
        top: 70px;
        scroll-behavior: smooth;
        scroll-snap-type: y mandatory;
        scrollbar-width: none; //for firefox

        &.mobile{
            top: 0;
        }

        &::-webkit-scrollbar{
            display: none;
        }

        > * {
            width: 100vw;
            min-height: calc(100vh - 70px);
            border-bottom: 15px solid rgba(0, 0, 0, 0.11);
            scroll-snap-align: start;
        }

        .no-scroll-snap {
            > * {
                width: 100vw;
                min-height: calc(100vh - 70px);
            }

        }

        .no-height {
            height: 0;
            min-height: 0;
        }
    }
}

* {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}